// Menu
.menu-enter {
  opacity: 0;
  transform: scale(0.9) translate(0, -4px);
  transform-origin: top right;
}
.menu-enter-active {
  opacity: 1;
  transform: scale(1) translate(0, 0);
  transition: all 120ms ease-out;
}
.menu-exit {
  opacity: 1;
  transform: scale(1) translate(0, 0);
  transform-origin: top right;
}
.menu-exit-active {
  opacity: 0;
  transform: scale(0.9) translate(0, -4px);
  transition: all 120ms ease-out;
}

// Dropdown
.dropdown-enter {
  opacity: 0;
  transform: scale(0.9) translate(0, -4px);
  transform-origin: top center;
}
.dropdown-enter-active {
  opacity: 1;
  transform: scale(1) translate(0, 0);

  transition: all 120ms ease-out;
}
.dropdown-exit {
  opacity: 1;
  transform: scale(1) translate(0, 0);
  transform-origin: top center;
}
.dropdown-exit-active {
  opacity: 0;
  transform: scale(0.9) translate(0, -4px);
  transition: all 120ms ease-out;
}

// Switch
.switch-enter {
  opacity: 0;
  transform: scale(0.75) translate(0, -16px);
}
.switch-enter-active {
  opacity: 1;
  transform: scale(1) translate(0, 0);
  transition: all 160ms ease-out;
}
.switch-exit {
  opacity: 1;
  transform: scale(1) translate(0, 0);
}
.switch-exit-active {
  opacity: 0;
  transform: scale(0.75) translate(0, 16px);
  transition: all 160ms ease-out;
}

// Pop up
.modal-enter {
  background: rgba(0, 0, 0, 0);
  .window {
    transform: scale(0.9);
    opacity: 0;
  }
}
.modal-enter-active {
  background: rgba(0, 0, 0, 0.5);
  transition: all 160ms ease-out;
  .window {
    transform: scale(1);
    opacity: 1;
    transition: all 160ms ease-out;
  }
}
.modal-exit {
  background: rgba(0, 0, 0, 0.5);
  .window {
    opacity: 1;
    transform: scale(1);
  }
}
.modal-exit-active {
  background: rgba(0, 0, 0, 0);
  transition: all 160ms ease-out;
  .window {
    transform: scale(0.9);
    opacity: 0;
    transition: all 160ms ease-out;
  }
}
