@import url("./fonts/fonts.css");
@import "./variables.scss";

html {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  line-height: 1.4;
  height: 100%;
}
/* @supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
} */

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

h1 {
  margin: 0 0 0.5rem;

  font-size: 2.5rem;
  line-height: 3rem;
}
p {
  margin: 0 0 1rem;
}

button {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.4;

  cursor: pointer;
}

input[type="text"] {
  font-family: inherit;
  font-size: 1rem;
}

input[type="checkbox"] {
  position: relative;
  appearance: none;
  bottom: -3px;
  margin: 0;
  margin-right: 4px;
  outline: none;

  &:after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    box-sizing: border-box;

    background: white;
    border-radius: 2px;
    border: 1px solid $c-medium-grey;
    transition: background-color 80ms ease-out;
  }
  &:checked:after {
    background: url(../svg/icon__check.svg) center center no-repeat, $c-primary;
    border: none;
  }
  &:focus:after {
    border: 1px solid $c-primary;
  }
  &:checked:focus:after {
    border: 1px solid black;
  }
}
