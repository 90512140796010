@import "css/variables.scss";
@import "css/animations.scss";
@import "./Dropdown/Dropdown.scss";
#root {
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Header
header {
  display: flex;
  height: 48px;
  box-sizing: content-box;

  background: white;
  box-shadow: 0 1px 0 rgba($color: black, $alpha: 0.12);
  z-index: 10;
}

.logo {
  padding: 0.5rem 1.5rem;

  background: $c-primary;

  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.filters {
  display: flex;
  flex: 1 1 0;
}

.selected {
  background-color: #e0e0e0;
}

.btn-switch {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0;
  height: 100%;

  background: white;
  border: none;
  user-select: none;
  transition: background-color 0.12s ease-out;

  &:disabled {
    background: white;
    opacity: 0.5;
    cursor: default;
  }
  &:disabled:hover > .content {
    background: white;
  }
  &:focus,
  .content:focus {
    outline: none;
  }
  &:hover > .content,
  &:focus > .content {
    background: $c-light-grey;
    outline: none;
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1.5rem;
    height: 100%;
    padding: 0 1rem;
    box-sizing: content-box;
  }
  img {
    position: absolute;
    right: 1rem;
  }
  @media only screen and (max-width: 1200px) {
    flex-grow: 0;
    .label {
      display: none;
    }
  }
}

// Main
main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  z-index: 5;
  overflow: auto;
  table {
    width: 100%;
    margin: 0.5rem auto;
    border-collapse: collapse;

    text-align: left;
  }
  td {
    word-break: break-word;
    vertical-align: top;
  }
  tr {
    border-bottom: 1px solid $c-light-grey;
  }
  th,
  td {
    padding: 0.5rem 0 0.5rem 1.5rem;
  }
  th:last-child,
  td:last-child {
    padding: 0.5rem 1.5rem 0.5rem 0;
  }
}

.download,
.play {
  display: flex;
  align-items: center;

  font-weight: 400;
  color: $c-primary;
  cursor: pointer;
  word-break: normal;
  transition: all 0.12s ease-out;

  svg {
    stroke: currentColor;
  }
  &:active svg {
    transform: scale(0.9);
  }
  &:hover {
    color: $c-red;
  }
  &:hover svg {
    stroke: $c-red;
    transition: all 0.12s ease-out;
  }
  &.disabled {
    color: $c-medium-grey;
    cursor: default;
  }
  &.disabled svg {
    stroke: $c-medium-grey;
  }
}
.map-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// Map
.leaflet-container {
  height: 100%;
}

.map-marker-wrapper {
  margin-left: 0 !important;
  margin-top: -36px !important;

  &:hover {
    z-index: 5000000 !important;
  }
}

.map-marker {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 2px 8px;
  transform: translateX(-50%);

  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);

  font-size: 14px;
  line-height: 1.4;
  cursor: default;
  white-space: nowrap;
  .play {
    margin-left: -4px;
  }
  span {
    padding: 2px 0;
  }
  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 9px);
    bottom: -9px;
    border-style: solid;
    border-width: 9px 9px 0;
    border-color: rgba(0, 0, 0, 0.16) transparent;
    display: block;
    width: 0;
  }
  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 8px);
    bottom: -7px;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
  }
}

.cluster {
  width: 44px;
  height: 44px;
  padding: 12px 0;

  background-color: $c-primary;
  border-radius: 22px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  color: white;

  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.legend {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: 300px;
  padding: 1rem;

  background: white;
  border-radius: 4px;

  font-size: 12px;
  z-index: 100;

  .item {
    display: flex;
    align-items: center;
  }
  .item:last-child {
    margin-bottom: 0;
  }

  .id_char {
    width: 1rem;
    margin: 0 0.25rem 0 0;
    padding-left: 3px;

    color: $c-red;
    font-size: 14px;
    font-weight: 700;
  }

  .transcript {
    margin-right: 0.25rem;

    font-size: 14px;

    &.cyan {
      color: $c-primary;
    }
  }

  .cluster {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    margin: 0 0.25rem 0 0;
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}