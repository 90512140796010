@import "../css/variables.scss";
.modal {
  position: absolute;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  overflow: auto;
}

.window {
  position: relative;
  max-width: 1000px;
  width: 64vw;
  min-height: 480px;
  margin: 64px auto;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1;

  .head {
    display: flex;
    padding: 0 0.5rem;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $c-medium-grey;

    user-select: none;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    li {
      display: inline-flex;
      align-items: center;
      height: 3rem;
      padding: 0 1rem;
      vertical-align: middle;
      cursor: pointer;
      transition: background-color 0.12s ease-out;

      &:hover {
        background: $c-light-grey;
      }
    }
    .control {
      display: flex;
    }
    .languages {
      margin-right: 14px;
    }
    img {
      margin-right: 0.25rem;
      opacity: 0.32;
      cursor: pointer;
      transition: opacity 0.12s ease-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 1320px) {
  .window {
    width: 90vw;
    margin: 40px auto;
  }
}

.window-content {
  padding: 1rem 1.5rem 1rem;
}

.modal-download {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 30px;
  max-width: 1000px;
  width: 64vw;
  margin: auto;
  margin-top: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: modalOpen 0.5s ease-out forwards;
}

// Add a backdrop for the modal
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@keyframes modalOpen {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.download-button {
  text-align: center;
  margin-top: 20px;
}

.download-button button {
  padding: 0.5rem 1.5rem;
  background: $c-primary;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: white;
  border: none;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.control {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
}

form {
  display: flex;
  flex-direction: column;

  .downloadmodal-input,
  textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 10px;
    outline: none;
    width: 100%;
    font-size: 1rem;
  }

  textarea {
    height: 100px;
    resize: vertical;
  }
}

.header {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;

  &:hover {
    color: darken(#000, 10%);
  }
}