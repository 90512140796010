.dropdown-wrapper {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 20%;
  @media only screen and (max-width: 1200px) {
    max-width: 25%;
  }
}
.btn-dropdown {
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  padding: 0 1rem;

  background: white;
  border: none;
  border-left: 1px solid $c-medium-grey;
  user-select: none;
  transition: background-color 0.12s ease-out;

  &:hover,
  &:focus {
    background: $c-light-grey;
  }
  .label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dropdown-drop {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  padding: 0.5rem 0;
  width: 100%;
  min-width: 250px;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  text-align: left;
  z-index: 100;

  a,
  .item {
    width: 100%;
    display: flex;
    padding: 0.5rem 0.75rem;

    text-decoration: none;
    color: #000000;
    transition: background-color 0.12s ease-out;
    cursor: pointer;

    &:hover {
      background: $c-light-grey;
    }
    input[type="checkbox"] {
      bottom: -1px;
    }
  }

  .item.period {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-panel {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-top: 1px solid $c-medium-grey;
    user-select: none;
  }
  .transcription-group {
    display: flex;
  }
  .transcription {
    min-width: 16px;
    margin-left: 2px;
    padding: 0 6px;

    border-radius: 2px;

    font-size: 12px;
    line-height: 23px;
    transition: background-color 0.12s ease-out;

    &.active {
      background: $c-primary;
      color: white;
      transition: background-color 0s;
    }
    &.alt:hover {
      background: white;
    }
  }
  &.search {
    padding: 0;
  }

  & > input[type="text"] {
    width: 100%;
    padding: 0.75rem;

    border: none;
    border-bottom: 1px solid $c-medium-grey;
    border-radius: 4px 4px 0 0;
    outline: none;

    font-size: 14px;
    line-height: 1.4;
  }
  & > input[type="text"]::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
.lang,
.switch {
  display: inline-block;
  height: 24px;
  margin-right: 2px;
  padding: 0 0.5rem;

  background: white;
  border: none;
  border-radius: 2px;
  box-shadow: none;

  font-size: 12px;
  line-height: 22px;
  transition: background-color 0.12s ease-out;
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background: white;
    cursor: default !important;
  }
  &.active {
    background: $c-primary;
    color: white;
    cursor: default;
    transition: background-color 0s;
  }
  &:focus {
    outline: none;
    // box-shadow: 0px 0 0 1px $c-primary;
  }
  &.alt:hover {
    background: $c-light-grey;
    cursor: pointer;
  }
}
.switch {
  margin-right: 0;
  &:hover {
    background: $c-light-grey;
    cursor: pointer;
  }
}
.dropdown-list {
  padding-top: 0.5rem;
  max-height: 50vh;
  overflow-y: auto;

  font-size: 14px;
  user-select: none;
}

.menu-wrapper {
  position: relative;
}
.btn-menu {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  height: 100%;

  background: white;
  border: none;
  border-left: 1px solid $c-medium-grey;

  user-select: none;
  transition: background-color 0.12s ease-out;

  &:hover,
  &:focus {
    outline: none;
    background: $c-light-grey;
  }
}

.menu-drop {
  position: absolute;
  top: calc(100% + 1px);
  right: 0.5rem;
  padding: 0.5rem 0;
  min-width: 184px;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  text-align: left;
  z-index: 100;

  a {
    width: 100%;
    display: inline-block;
    padding: 0.5rem 0.75rem;

    text-decoration: none;
    color: #000000;
    transition: background-color 0.12s ease-out;

    &:hover {
      background: $c-light-grey;
    }
  }
}

.input-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;

  background: white;
  border: none;
  border-left: 1px solid $c-medium-grey;
  transition: background-color 0.12s ease-out;

  &:hover {
    background: $c-light-grey;
  }

  & > input[type="text"] {
    width: 100%;
    height: 100%;
    padding: 0 1rem;

    border: none;
    outline: none;
  }
  & > input[type="text"]::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}

.phonetic {
  font-size: 14px;
  table {
    margin: 0;
    border-collapse: collapse;
  }
  td {
    padding: 0;
  }
}
.phonetic-tip {
  display: flex;
  padding: 8px 12px;

  .operator {
    height: 20px;
    min-width: 16px;
    padding: 0 4px;
    margin-right: 1rem;

    background: $c-light-grey;
    border-radius: 2px;

    line-height: 20px;
    text-align: center;
  }
  .label {
    height: 20px;
  }
}

.search .clear {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  right: 38px;

  background: url(svg/icon__close.svg) center no-repeat;
  background-size: 80%;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }
}

.help-tip {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  right: 12px;

  background: $c-light-grey;
  border-radius: 50%;

  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  cursor: default;

  .content {
    visibility: hidden;
    position: absolute;
    width: 16rem;
    top: calc(-50% - 8px);
    left: 28px;
    padding: 0.5rem;

    background: rgb(48, 48, 48);
    border-radius: 4px;
    opacity: 0;

    color: white;
    text-align: left;
    font-weight: 300;
    transition: opacity 0.12s ease-out;
  }
  .content:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: rgb(48, 48, 48);
    border-width: 4px;
    margin-top: -4px;
  }
  &:hover .content {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.12s ease-out;
  }
}
